<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('i18nn_eb763bfab5d5a930') }}
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcelAction()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('i18nn_eb763bfab5d5a930') }}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
					<!-- <li> -->
					<el-tooltip effect="dark" content="无面单地址,撤销打单" placement="bottom">
						<el-button type="danger" size="small" icon="el-icon-close"
							@click="CancelPrintingAction($event)">{{ $t('i18nn_4ca88dd2e7071844') }}</el-button>
					</el-tooltip>
					<!-- </li> -->
					<!-- <li> -->
					<el-tooltip effect="dark" content="有面单地址,取消面单(需渠道方支持)" placement="bottom">
						<el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event)">
							{{ $t('i18nn_bcef26d98d690aba') }}</el-button>
					</el-tooltip>
					<el-tooltip effect="dark" content="删除记录,需先撤销或取消(特殊情况使用,如:订单号重复)" placement="bottom">
						<el-button @click="delAction($event, null)" type="danger" size="small" icon="el-icon-minus">删除</el-button>
					</el-tooltip>
					<!-- </li> -->
					<!-- <li> -->
					<!-- <el-button @click="batchDownloadAction($event)" type="primary" size="small"
						icon="el-icon-download">{{$t('i18nn_21b22a8018f3cba2')}}</el-button> -->

					<el-dropdown size="small" @command="handleMoreAction($event)">
						<el-button type="primary" plain size="small" style="margin:0 10px">
							<span>{{$t('i18nn_21b22a8018f3cba2')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="batchDownloadAction_1" icon="el-icon-download">
								<span>按默认名称</span>
							</el-dropdown-item>
							<el-dropdown-item divided command="batchDownloadAction_2" icon="el-icon-download">
								<span>按运单号命名</span>
							</el-dropdown-item>
							<el-dropdown-item divided command="batchDownloadAction_3" icon="el-icon-download">
								<span>按订单号命名</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<!-- </li> -->
					<!-- </ul> -->

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.labelStatus" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.labelStatusList" :key="item.value">
						<span>{{i18nFormatter(item.value)}}</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event)">
							{{ $t('i18nn_bcef26d98d690aba') }}</el-button>
					</li>
					<li>
						<el-button type="danger" size="small" icon="el-icon-close"
							@click="CancelPrintingAction($event)">{{ $t('i18nn_4ca88dd2e7071844') }}</el-button>
					</li>
					<li>
						<el-button @click="batchDownloadAction($event, null)" type="primary" size="small"
							icon="el-icon-download">{{$t('i18nn_21b22a8018f3cba2')}}</el-button>
					</li>
				</ul> -->
				<ul class="filterConList">
					<li>
						<span class="filterName">{{ $t('i18nn_06dd7cb65641390b') }}</span>
						<el-select filterable clearable v-model="filterData.labelReady" :placeholder="$t('i18nn_2ad108ab2c560530')"
							size="small" style="width: 100px;" @change="initData()">
							<el-option v-for="item in selectOption.labelReadyList" :key="item.value"
								:label="i18nFormatter2(item.value)" :value="item.value"></el-option>
						</el-select>
					</li>
					<li>
						<span class="filterName">{{ $t('i18nn_abf9f4b8c93d641e') }}</span>
						<el-input type="text" v-model="filterData.orderNumber" size="small" clearable
							@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_5a9aefbc03c778f7')"
							style="width: 180px;" />
						<!-- <el-button icon="el-icon-magic-stick" size="small" type="success" plain
							@click="openOrderInputAction()">多订单号搜索</el-button> -->
						<el-tooltip class="" effect="dark" :content="'多订单号搜索'" placement="top">
							<el-button icon="el-icon-s-operation" size="small" type="success" plain
								@click="openOrderInputAction()"></el-button>
						</el-tooltip>
					</li>
					
					<li>
						<span>运单号</span>
						<el-input type="text" v-model="filterData.tranckingNo" size="small" clearable
							@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('hytxs0000001')" style="width: 180px;" />
						<!-- <el-button icon="el-icon-magic-stick" size="small" type="success" plain
							@click="openTranckingNoInputAction()">多运单号</el-button> -->
						
						<el-tooltip class="" effect="dark" :content="'多运单号搜索'" placement="top">
							<el-button icon="el-icon-s-operation" size="small" type="success" plain
								@click="openTranckingNoInputAction()"></el-button>
						</el-tooltip>
						
					</li>
					
					<li>
						<span class="filterName">{{ $t('i18nn_5b7f75f3443354a2') }}</span>
						<el-date-picker v-model="filterData.daterange" size="small" type="daterange" align="right" :clearable="true"
							unlink-panels range-separator="-" start-placeholder="start date" end-placeholder="end date"
							:picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="initData()"
							style="width: 220px;"></el-date-picker>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 200px;">
						</el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<li>
						<el-button :icon="drawerFilter?'el-icon-arrow-up':'el-icon-arrow-down'" size="small" type="primary"
							@click="openMoreFilter()">
						</el-button>
					</li>
				</ul>

			</div>

		</div>
		<!--更多筛选-->
		<!-- <el-drawer append-to-body :wrapperClosable="false" custom-class="drawerFilter" :title="$t('i18nn_c4ca802cf2230b0b')" :visible.sync="drawerFilter" :direction="'rtl'"> -->
		<div class="drawerFilterCon" v-show="drawerFilter">
			<ul class="drawerFilterList">
				<li>
					<span class="filterName">{{ $t('i18nn_f77f473d27f48bdc') }}</span>
					<SelAgentUser ref="SelAgentUser" @change="changeAgentUser" size="small"></SelAgentUser>
				</li>
				<li>
					<span class="filterName">{{$t('i18nn_c944a6686d996ab3')}}</span>
					<whNoSelect @changeData="changWhNo" size="small"></whNoSelect>
				</li>


				<li class="filterBtnCon">
					<el-button icon="el-icon-search" size="small" type="primary"
						@click="queryMoreFilter()">{{$t('i18nn_1e7246dd6ccc5539')}}</el-button>
				</li>
			</ul>
		</div>
		<!-- </el-drawer> -->
		<ul class="filterConList" style="text-align: left;" v-if="filterData.orderNumberList && filterData.orderNumberList.length > 0">
			<el-button icon="el-icon-close" size="small" type="success" @click="clearOrderList()">
				<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
				(<span>多订单号搜索</span>)
				(<span>共<span>{{filterData.orderNumberList.length}}</span></span>)
			</el-button>
			<!-- // <li><span>{{$t('i18nn_abf9f4b8c93d641e')}}</span></li> -->
			<li v-for="(item, index) in filterData.orderNumberList" :key="index">
				<el-tag>{{ item }}</el-tag>
			</li>
			<!-- <li>
				<el-button icon="el-icon-close" size="small" type="success" plain @click="clearOrderList">清空</el-button>
			</li> -->
		</ul>
		
		<ul class="filterConList" style="text-align: left;" v-if="filterData.tranckingNoList && filterData.tranckingNoList.length > 0">
		
			<el-button icon="el-icon-close" size="small" type="success" @click="clearTranckingNoList()">
				<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
				(<span>多运单号搜索</span>)
				(<span>共<span>{{filterData.tranckingNoList.length}}</span></span>)
			</el-button>
		
			<!-- <li><span>运单号</span></li> -->
			<li v-for="(item, index) in filterData.tranckingNoList" :key="index">
				<el-tag>{{ item }}</el-tag>
			</li>
			<!-- <li>
				<el-button icon="el-icon-close" size="small" type="success" plain
					@click="clearTranckingNoList">清空</el-button>
			</li> -->
		</ul>
		
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_90429ac39f4f4634') }}</span>
				<el-radio-group v-model="filterData.labelStatus" size="medium" @change="initData()">
					<el-radio-button border v-for="item in selectOption.labelStatusList" :key="item.value" :label="item.value">{{ item.label }}</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->

		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_c944a6686d996ab3') }}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_5b7f75f3443354a2') }}</span>
				<el-date-picker
					v-model="filterData.daterange"
					size="small"
					type="daterange"
					align="right"
					:clearable="false"
					unlink-panels
					range-separator="-"
					start-placeholder="start date"
					end-placeholder="end date"
					:picker-options="pickerOptions"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					@change="initData()"
				></el-date-picker>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_06dd7cb65641390b') }}</span>
				<el-select filterable clearable v-model="filterData.labelReady" :placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
					<el-option v-for="item in selectOption.labelReadyList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_abf9f4b8c93d641e') }}</span>
				<el-input
					type="text"
					v-model="filterData.orderNumber"
					size="small"
					clearable
					@keyup.enter.native="initData()"
					maxlength="50"
					:placeholder="$t('i18nn_5a9aefbc03c778f7')"
					style="width: 180px;"
				/>
			</div>
		 <div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="initData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->
		<!-- <div class="wh-action-con">
			<el-button type="warning" size="small" icon="el-icon-delete" @click="CancelOrderAction($event)">{{ $t('i18nn_bcef26d98d690aba') }}</el-button>

			<el-button type="danger" size="small" icon="el-icon-close" @click="CancelPrintingAction($event)">{{ $t('i18nn_4ca88dd2e7071844') }}</el-button>

			<el-button @click="batchDownloadAction($event, null)" type="primary" size="small" icon="el-icon-download">{{$t('i18nn_21b22a8018f3cba2')}}</el-button>
		</div> -->
		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1" @row-click="handleCurrentChange"
				@selection-change="handleSelectionChange" v-loading="loading">

				<el-table-column type="selection" fixed="left" align="center" width="55"></el-table-column>
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column type="expand" fixed="left">
					<template slot-scope="scope">
						<div style="margin: 0 200px;">
							<el-descriptions title="" direction="vertical" :column="1" :size="'mini'" border>
								<el-descriptions-item label="labelRequest">
									<div v-if="scope.row.labelRequestObj">
										<!-- VITEUPS("biz_wh_lgskey_vite_ups","V-ups","UPS"), -->
										<!-- VITEFEDEX("biz_wh_lgskey_vite_fedex","V-fedex","Fedex"), -->
										<!-- VITEUSPS("biz_wh_lgskey_vite_usps","V-usps","USPS"), -->
										<div v-if="scope.row.labelRequestObj.packages">
											<ul v-for="(item,index) in scope.row.labelRequestObj.packages" :key="index">
												<li>
													<span>体积(l*w*h)</span>：
													<span>{{item.length}}</span>*
													<span>{{item.width}}</span>*
													<span>{{item.height}}</span>
												</li>
												<li>
													<span>{{$t('i18nn_8004917a89ed807b')}}</span>：
													<span>{{item.weight}}</span>
												</li>
											</ul>
										</div>
										<!-- COPEFEDEX("biz_wh_lgskey_cope_fedex","S-Fedex","Fedex"), -->
										<div v-if="scope.row.labelRequestObj.shipment && scope.row.labelRequestObj.shipment.packages">
											<ul v-for="(item,index) in scope.row.labelRequestObj.shipment.packages" :key="index">
												<li v-if="item.dimension">
													<span>体积(l*w*h)({{item.dimension.code}})</span>：
													<span>{{item.dimension.length}}</span>*
													<span>{{item.dimension.width}}</span>*
													<span>{{item.dimension.height}}</span>
													<!-- <span>{{item.dimension.code}}</span> -->
												</li>
												<li v-if="item.weight">
													<span>重({{item.weight.code}})</span>：
													<span>{{item.weight.value}}</span>
													<!-- <span>{{item.weight.code}}</span> -->
												</li>
											</ul>
										</div>
										<pre style="margin:0;">
										{{scope.row.labelRequestObj}}
										</pre>
									</div>
								</el-descriptions-item>

								<el-descriptions-item label="labelRespon">
									<div style="">
										{{scope.row.labelRespon}}
									</div>
								</el-descriptions-item>
							</el-descriptions>

							<!-- {{scope.row.labelRequestObj.packages}}<br /> -->
							<!-- <div><strong>labelRequest:</strong></div>
							<pre>
								{{scope.row.labelRequestObj}}
							</pre>
							<br />
							<div><strong>labelRespon:</strong></div>
							<div style="padding-right: 200px;">
								{{scope.row.labelRespon}}
							</div> -->

						</div>
					</template>
				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->
				<!-- <el-table-column prop="carrier" :label="$t('i18nn_e716ca411b2142e3')"></el-table-column> -->
				<!-- <el-table-column prop="carrier" :label="$t('i18nn_e716ca411b2142e3')"></el-table-column>
				<el-table-column prop="channel" :label="$t('i18nn_3d6d2d670fa63b7c')"></el-table-column>
				<el-table-column prop="channelName" :label="$t('i18nn_316e16926411681a')"></el-table-column> -->

				<el-table-column prop="" :label="'渠道信息'" min-width="200">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span class="">{{$t('i18nn_316e16926411681a')}}</span>:
								<span>{{scope.row.channelName}}</span>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_e5c3fd867ea4c8e6')}}</span>:
								<span>{{scope.row.lgsKeyCode}}</span>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_e716ca411b2142e3')}}</span>:
								<span>{{scope.row.carrier}}</span>
							</div>

							<div class="recItem">
								<span class="">{{$t('i18nn_3d6d2d670fa63b7c')}}</span>:
								<span>{{scope.row.channel}}</span>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_3ad90a648c6bfa99')}}</span>:
								<span>{{scope.row.carrierServiceLevel}}</span>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="tranckingNo" :label="$t('i18nn_4531476fa35570f0')" width="150px">
					<template slot-scope="scope">
						<div>
							<div class="link-copy" v-if="scope.row.tranckingNo">
								<span class="link-text" @click="toExpLgsTrackView($event, scope.row, scope.$index)">
									{{ scope.row.tranckingNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.tranckingNo)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="carrierServiceLevel" :label="$t('i18nn_3ad90a648c6bfa99')"></el-table-column> -->
				<!-- <el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column> -->

				<!-- <el-table-column prop="orderNumber" :label="$t('i18nn_abf9f4b8c93d641e')"></el-table-column> -->


				<el-table-column prop="" :label="$t('i18nn_4531476fa35570f0')+'/'+$t('i18nn_abf9f4b8c93d641e')" min-width="150">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span class="">{{$t('i18nn_4531476fa35570f0')}}</span>:
								<div class="link-copy" v-if="scope.row.tranckingNo">
									<span class="link-text" @click="toExpLgsTrackView($event, scope.row, scope.$index)">
										{{ scope.row.tranckingNo }}
									</span>
									<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
										placement="top">
										<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.tranckingNo)"></i>
									</el-tooltip>
								</div>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_abf9f4b8c93d641e')}}</span>:
								<span>{{scope.row.orderNumber}}</span>
							</div>
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="" :label="'出单进度'" min-width="150">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span class="">{{$t('i18nn_310c8b0f4df16e08')}}</span>:
								<el-tag size="mini" effect="plain" type="success"
									v-if="'1'==scope.row.isBuy">{{scope.row.isBuyName}}</el-tag>
								<el-tag size="mini" effect="plain" type="warning"
									v-else-if="'0'==scope.row.isBuy">{{scope.row.isBuyName}}</el-tag>
								<el-tag size="mini" effect="plain" type="primary" v-else>{{scope.row.isBuyName}}</el-tag>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_82b1c8e36ef45bb8')}}</span>:
								<!-- <el-tag size="mini" effect="plain" class="">{{scope.row.isRecordName}}</el-tag> -->
								<el-tag size="mini" effect="plain" type="success"
									v-if="'1'==scope.row.isRecord">{{scope.row.isRecordName}}</el-tag>
								<el-tag size="mini" effect="plain" type="warning"
									v-else-if="'0'==scope.row.isRecord">{{scope.row.isRecordName}}</el-tag>
								<el-tag size="mini" effect="plain" type="primary" v-else>{{scope.row.isRecordName}}</el-tag>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_06dd7cb65641390b')}}</span>:
								<!-- <el-tag size="mini" effect="plain" class="">{{scope.row.labelReadyName}}</el-tag> -->
								<el-tag size="mini" effect="plain" type="success"
									v-if="'1'==scope.row.labelReady">{{scope.row.labelReadyName}}</el-tag>
								<el-tag size="mini" effect="plain" type="warning"
									v-else-if="'0'==scope.row.labelReady">{{scope.row.labelReadyName}}</el-tag>
								<el-tag size="mini" effect="plain" type="primary" v-else>{{scope.row.labelReadyName}}</el-tag>
							</div>
							<div class="recItem">
								<span class="">{{$t('i18nn_90429ac39f4f4634')}}</span>:
								<!-- <el-tag size="mini" effect="plain" class="">{{scope.row.labelStatusName}}</el-tag> -->
								<el-tag size="mini" effect="plain" type="success"
									v-if="'20'==scope.row.labelStatus">{{scope.row.labelStatusName}}</el-tag>
								<el-tag size="mini" effect="plain" type="warning"
									v-else-if="'10'==scope.row.labelStatus">{{scope.row.labelStatusName}}</el-tag>
								<el-tag size="mini" effect="plain" type="primary" v-else>{{scope.row.labelStatusName}}</el-tag>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="isBuyName" :label="$t('i18nn_310c8b0f4df16e08')"></el-table-column>
				<el-table-column prop="isRecordName" :label="$t('i18nn_82b1c8e36ef45bb8')"></el-table-column>

				<el-table-column prop="labelReadyName" :label="$t('i18nn_06dd7cb65641390b')"></el-table-column>
				<el-table-column prop="labelStatusName" :label="$t('i18nn_90429ac39f4f4634')">
					<template slot-scope="scope">
						<div>
							<el-tag type=""
								v-if="'10' == scope.row.labelStatus">{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
							<el-tag type="success"
								v-else-if="'20' == scope.row.labelStatus">{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
							<el-tag type="warning"
								v-else-if="'60' == scope.row.labelStatus">{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
							<el-tag type="danger"
								v-else-if="'70' == scope.row.labelStatus">{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
							<el-tag type="warning"
								v-else-if="'90' == scope.row.labelStatus">{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
							<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'labelStatusName') }}</el-tag>
						</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="labelRequest" :label="$t('i18nn_728bf6a36a68e566')" show-overflow-tooltip></el-table-column> -->
				<!-- <el-table-column prop="labelRespon" :label="'labelRespon'" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="labelUrl" :label="$t('i18nn_1324a1d91ae3de53')" width="150px" show-overflow-tooltip>
					<template slot-scope="scope">
						<div>
							<a :href="scope.row.labelUrl" target="_blank">{{ scope.row.labelUrl }}</a>
						</div>
					</template>
				</el-table-column>



				<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
					<!-- <template slot-scope="scope">
						<div style="color:red;">{{ scope.row.remark }}</div>
					</template> -->
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="lgsKeyCode" :label="$t('i18nn_e5c3fd867ea4c8e6')"></el-table-column> -->


				<!-- <el-table-column prop="labelSeq" :label="$t('i18nn_c6b76f2746d7dc6f')" min-width="100"></el-table-column> -->

				<!-- <el-table-column prop="labelOrderNum" :label="$t('i18nn_48ebfc4319cbac59')" width=""> -->
				</el-table-column>

				<el-table-column prop="" :label="$t('i18nn_c6b76f2746d7dc6f')" min-width="150">
					<template slot-scope="scope">
						<div class="recDet">
							<div class="recItem">
								<span class="">流水号</span>:
								<span>{{scope.row.labelSeq}}</span>
							</div>
							<div class="recItem">
								<span class="">打单号</span>:
								<span>{{scope.row.labelOrderNum}}</span>
							</div>
						</div>
					</template>
				</el-table-column>



				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->

				<!-- <el-table-column prop="carrier" :label="$t('i18nn_b0c50b6ff9674173')"></el-table-column> -->

				<!-- <el-table-column prop="carrierServiceLevel" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column> -->

				<!-- <el-table-column prop="tranckingNo" :label="$t('i18nn_4531476fa35570f0')" width="150px">
				</el-table-column> -->

				<!-- <el-table-column prop="shippingPrice" :label="$t('i18nn_ab6994d16b9b4366')"></el-table-column> -->
				<!-- <el-table-column prop="shippintDesc" :label="$t('i18nn_673767ab6ddb37cd')"></el-table-column> -->

				<!-- <el-table-column prop="serviceFee" :label="$t('i18nn_d353bf2887ca0603')"></el-table-column> -->

				<el-table-column prop="serviceFee" :label="$t('i18nn_3c4a40675f59cd1c')">
					<template slot-scope="scope">
						<div>
							{{ (parseFloat(scope.row.shippingPrice?scope.row.shippingPrice:0) + parseFloat(scope.row.serviceFee?scope.row.serviceFee:0)+ parseFloat(scope.row.sellerFee?scope.row.sellerFee:0)).toFixed(2) }}
						</div>
					</template>
				</el-table-column>


				<el-table-column prop="labelTime" :label="$t('i18nn_5f6b842a6ff748ab')"></el-table-column>
				<el-table-column prop="createTime" :label="$t('i18nn_76e82afd9c2b6ca1')"></el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="120px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>
							<div style="margin-bottom: 10px;" v-if="'70' == scope.row.labelStatus||'90' == scope.row.labelStatus">
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 订单号输入框 -->
		<el-dialog :title="$t('i18nn_33bb5e57aba192e1')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogOrderInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.orderNumberListStr" size="small"
					clearable show-word-limit maxlength="1000" :placeholder="$t('i18nn_b92065474c13bf38')"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogOrderInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureOrderInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>
		
		<!-- 运单号输入框 -->
		<el-dialog :title="'运单号'" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogTranckingNoInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.tranckingNoListStr" size="small"
					clearable show-word-limit maxlength="1000" :placeholder="'一行输入一个'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogTranckingNoInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureTranckingNoInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>
		
		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
      <div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
    </el-dialog> -->
		<!-- 发货轨迹查询 -->
		<whQueryExpLgsTrack :openTime="openTimeExpLgsTrack" :row="ExpLgsTrackRow"></whQueryExpLgsTrack>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhFuerthertryDetPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
		ExpressSheetDetList_status,
		ExpressSheetDetList_status2
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	import {
		fileZipAndDownloadReName
	} from '@/utils/fileZipDownload.js';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import whQueryExpLgsTrack from '@/components/StorageCenter/components/whQueryExpLgsTrack.vue';
	export default {
		components: {
			SelAgentUser,
			whExcelCustom,
			whNoSelect,
			whQueryExpLgsTrack
		},
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			openTime: {

			},
			exprId: {
				default: function() {
					return '';
				},
				type: String
			}
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},
				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改
				drawerFilter: false,
				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',
				openTimeExpLgsTrack: '',
				ExpLgsTrackRow: {},

				//订单号输入弹窗
				dialogOrderInputVisible: false,
				//运单号输入弹窗
				dialogTranckingNoInputVisible: false,

				pickerOptions: this.$PickerDate.pickerOptions_2(),

				loading: false,

				loading_load: false,
				tableData: [],

				multipleSelection: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					// wh_size_unit: [],
					// // wh_packing_type:[],
					// wh_car_tms_type: [],
					// wh_no: [],
					labelReadyList: ExpressSheetDetList_status2,
					labelStatusList: ExpressSheetDetList_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// agentUser:'',
					// whNo:'',

					daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils
						.fomatterDate_YYYYMMdd(new Date().getTime() + 3600 * 1000 * 24)
					],
					// exprStatus: '',
					whNo: '',
					orderNumber: '',
					orderNumberList: [],
					orderNumberListStr: '',
					
					tranckingNo:'',
					tranckingNoList:[],
					tranckingNoListStr: '',
					
					// cusNo: '',
					commitDate: '',
					keyword: '',
					labelReady: '',
					labelStatus: '',
					agentUser: ''
					// "accountPeriod":"",
					// packId: "",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: '',
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				if (!!this.exprId) {
					this.filterData.daterange = [];
				}
				this.initData();
				// }
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				// if(!!this.exprId){
				// 	this.filterData.daterange = [];
				// }
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				// if (this.exprId) {
				this.tableData = [];
				this.getPageData();
				// }
				// this.getDicData();
			},
			//打开更多筛选
			openMoreFilter() {
				this.drawerFilter = !this.drawerFilter;
			},
			//查询更多筛选
			queryMoreFilter() {
				this.initData();
				// this.drawerFilter = false;
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.labelStatusList)
			},
			i18nFormatter2(value) {
				return i18nStatus(value, this.selectOption.labelReadyList)
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			exportExcelAction() {
				let columns = [{
						title: this.$t('i18nn_c944a6686d996ab3'),
						key: 'whNo'
					},
					// {
					// 	title: this.$t('i18nn_29991afa9781d554'),
					// 	key: 'cusName'
					// },

					{
						title: this.$t('i18nn_316e16926411681a'),
						key: 'channelName'
					},
					{
						title: this.$t('i18nn_e5c3fd867ea4c8e6'),
						key: 'lgsKeyCode'
					},
					{
						title: this.$t('i18nn_e716ca411b2142e3'),
						key: 'carrier'
					},
					{
						title: this.$t('i18nn_3d6d2d670fa63b7c'),
						key: 'channel'
					},
					{
						title: this.$t('i18nn_3ad90a648c6bfa99'),
						key: 'carrierServiceLevel'
					},

					{
						title: this.$t('i18nn_4531476fa35570f0'),
						key: 'tranckingNo'
					},
					{
						title: this.$t('i18nn_abf9f4b8c93d641e'),
						key: 'orderNumber'
					},

					{
						title: this.$t('i18nn_310c8b0f4df16e08'),
						key: 'isBuyName'
					},
					{
						title: this.$t('i18nn_82b1c8e36ef45bb8'),
						key: 'isRecordName'
					},
					{
						title: this.$t('i18nn_06dd7cb65641390b'),
						key: 'labelReadyName'
					},
					{
						title: this.$t('i18nn_90429ac39f4f4634'),
						key: 'labelStatusName'
					},
					{
						title: this.$t('i18nn_1324a1d91ae3de53'),
						key: 'labelUrl'
					},
					{
						title: this.$t('i18nn_c6b76f2746d7dc6f'),
						key: 'labelSeq'
					},
					{
						title: this.$t('i18nn_48ebfc4319cbac59'),
						key: 'labelOrderNum'
					},


					// {
					// 	title: this.$t('i18nn_4531476fa35570f0'),
					// 	key: 'tranckingNo'
					// },

					{
						title: this.$t('i18nn_3c4a40675f59cd1c'),
						key: 'totalServiceFee',
						types: 'custom',
						formatter: function(row) {
							if (row) {
								return (parseFloat(row.shippingPrice ? row.shippingPrice : 0) + parseFloat(row
									.serviceFee ? row.serviceFee : 0) + parseFloat(row.sellerFee ? row
									.sellerFee : 0)).toFixed(2);
							} else {
								return '';
							}
						}
					},
					{
						title: this.$t('i18nn_15b3627faddccb1d'),
						key: 'remark'
					},
					{
						title: this.$t('i18nn_5f6b842a6ff748ab'),
						key: 'labelTime'
					},
					{
						title: this.$t('i18nn_76e82afd9c2b6ca1'),
						key: 'createTime'
					}
				];
				// let Data = this.tableDataCatch;
				// return columns;
				let Data = this.tableData;
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'ExpressSheetDetList';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// let data_dom = JQ('#ex_table').find(".el-table__body");
				// let obj_key_img = {};
				// // console.log(data_dom.find("tr"));
				// data_dom.find("tr").each((index, domEle)=>{
				// 	// console.log("index",index);
				// 	let imgObj = JQ(domEle).find("td .cell img");
				// 	// console.log("imgObj",imgObj);
				// 	if(imgObj){
				// 		obj_key_img[imgObj.attr("code")] = imgObj.attr("src");
				// 	}
				// })
				// // console.log("obj_key_img",obj_key_img);
				// Data.forEach(item=>{
				// 	item.goodsSkuImg = obj_key_img[item.goodsSku];
				// })
				// console.log('Data',Data);
				// return;
				// excelUtilsNew.exportExcel(columns, Data, 'wh_dropshipping',{height:20});
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//发货单轨迹查询
			toExpLgsTrackView(event, row) {
				event.stopPropagation();
				// this.$router.push({ name: 'WhDropShippingDet', query: { orderNumber: row.orderNumber } });
				if (row.carrier && row.tranckingNo) {
					this.openTimeExpLgsTrack = new Date().getTime();
					this.ExpLgsTrackRow = {
						carrier: row.carrier,
						tracking: row.tranckingNo,
						id: row.exprId
					};
				} else {
					this.$message.warning(this.$t('i18nn_c136c2ffbc49c6e7'));
				}
			},
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form',form);
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) { //新增

			//     this.form.weightUnit = '1';
			//     this.form.volumeUnit = '1';
			//     this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   } else { //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   }
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },
			delAction(event, row) {
				event.stopPropagation();
				// this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				// 	type: 'warning'
				// })
				// 	.then(() => {
				// 		this.delDataAction(event, row);
				// 	})
				// 	.catch(() => {
				// 	});
				let parm = [];
				if (row) {
					parm = [row.id];
				} else {
					//多条
					let dataList = this.multipleSelection;
					// let dataList = this.tableData;
					if (dataList.length < 1) {
						this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
						return;
					}
					if (dataList.findIndex(item => '70' != item.labelStatus && '90' != item.labelStatus) > -1) {
						// this.$message.warning('撤销状态才可以删除');
						this.$alert('只有取消面单或撤销打单状态才可以删除！', this.$t('tips.tipsTitle'), {
							type: 'warning',
						});
						return;
					}
					// let dataListParm = dataList.map(v => v.id);
					parm = dataList.map(v => v.id);
				}

				// let parm = [];
				// let dataList = this.multipleSelection;
				// // let dataList = this.tableData;
				// if (dataList.length < 1) {
				// 	this.$message.warning(this.$t('i18nn_fc089dc894530f12'));
				// 	return;
				// }

				// 	}
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhThridLabelInfoDetDelBatch, {
							"ids": parm
						}, () => {
							this.initData();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {});

			},
			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			// this.$message({
			// 			//   type: 'success',
			// 			//   message: '删除成功!'
			// 			// });
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {
			// 			// this.$message({
			// 			//   type: 'info',
			// 			//   message: this.$t('i18nn_2e58cb9b52e2a214')
			// 			// });
			// 		});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);

			// 	this.postData(
			// 		this.$urlConfig.WhThridLabelInfoDetDel + '/' + row.id, {},
			// 		() => {
			// 			this.initData();
			// 			this.$message.success('删除成功！');
			// 		},
			// 		'delete'
			// 	);
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);

			//       } else {

			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//查询数据
			// serPageData() {
			// 	// this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.labelStatus = key;
				this.initData();
			},

			//打开订单的输入框
			openOrderInputAction() {
				// this.filterData.orderNumberListStr = "";
				this.dialogOrderInputVisible = true;
			},
			//确定订单的输入框
			sureOrderInputAction() {
				this.dialogOrderInputVisible = false;
				this.filterData.orderNumber = '';
				let orderNumberList = [];
				if (this.filterData.orderNumberListStr) {
					orderNumberList = this.filterData.orderNumberListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.orderNumberList = orderNumberList;
				this.initData();
			},
			//清除搜索订单号的值
			clearOrderList() {
				this.filterData.orderNumberListStr = '';
				this.filterData.orderNumberList = [];
				this.initData();
			},
			
			//打开运单的输入框
			openTranckingNoInputAction() {
				// this.filterData.orderNumberListStr = "";
				this.dialogTranckingNoInputVisible = true;
			},
			//确定订单的输入框
			sureTranckingNoInputAction() {
				this.dialogTranckingNoInputVisible = false;
				this.filterData.tranckingNo = '';
				let tranckingNoList = [];
				if (this.filterData.tranckingNoListStr) {
					tranckingNoList = this.filterData.tranckingNoListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.tranckingNoList = tranckingNoList;
				this.initData();
			},
			//清除搜索的值
			clearTranckingNoList() {
				this.filterData.tranckingNoListStr = '';
				this.filterData.tranckingNoList = [];
				this.initData();
			},

			//分页的筛选项数据
			pageFilterData() {
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('tips.Please_Sel_date'));
				}

				let orderNumberList = [];
				if (this.filterData.orderNumberListStr) {
					orderNumberList = this.filterData.orderNumberList;
				} else if (this.filterData.orderNumber) {
					orderNumberList = [this.filterData.orderNumber];
				}
				
				let tranckingNoList = [];
				if (this.filterData.tranckingNoListStr) {
					tranckingNoList = this.filterData.tranckingNoList;
				} else if (this.filterData.tranckingNo) {
					tranckingNoList = [this.filterData.tranckingNo];
				}

				return {
					exprId: this.exprId, //"orde的ID",
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// exprStatus: this.filterData.exprStatus ? this.filterData.exprStatus : null,
					// orderNumber: this.filterData.orderNumber ? this.filterData.orderNumber : null,
					orderNumberList: orderNumberList.length > 0 ? orderNumberList : null,
					tranckingNoList: tranckingNoList.length > 0 ? tranckingNoList : null,
					// userId: this.filterData.cusNo ? this.filterData.cusNo : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					labelReady: this.filterData.labelReady ? this.filterData.labelReady : null,
					labelStatus: this.filterData.labelStatus ? this.filterData.labelStatus : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,

					createTimeStart: startTime ? startTime : null,
					createTimeEnd: endTime ? endTime : null
				};
			},

			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhFuerthertryDetPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						// this.tableData = data.rows;

						let tableData = data.rows;
						tableData.forEach(item => {
							try {
								item.labelRequestObj = JSON.parse(item.labelRequest);
								// item.labelResponObj = JSON.parse(item.labelRespon);
							} catch (err) {
								console.log(err);
							}
						});

						this.tableData = tableData;

						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//撤销打单
			CancelPrintingAction(event) {
				event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				let parm = [];
				// 	if (row) {
				// 		parm = [row.id];
				// 	} else {
				// 		//多条
				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
				// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
				// 		//   return;
				// 		// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// 	}
				this.$confirm('确定 撤销打单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhThridLabelCancelPrinting, parm, () => {
							this.initData();
							this.$message.success(this.$t('i18nn_778d871227234f32'));
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},
			//取消面单
			CancelOrderAction(event) {
				event.stopPropagation();
				// this.getPriterOrderRate(row,index,row.id);
				let parm = [];
				// 	if (row) {
				// 		parm = [row.id];
				// 	} else {
				// 		//多条
				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				// 		// if (dataList.findIndex(item=> '35'!=item.exprStatus)>-1) {
				// 		//   this.$message.warning(this.$t('i18nn_493d12b09fa54b95'));
				// 		//   return;
				// 		// }

				let dataListParm = dataList.map(v => v.id);
				parm = dataListParm;
				// 	}
				this.$confirm('确定 取消面单 吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhThridLabelCancelOrder, parm, () => {
							this.initData();
							this.$message.success(this.$t('i18nn_854a071f3cbf2761'));
							// this.$router.push({name:'WhPackage',query:{open:'add'}});
						});
					})
					.catch(() => {
						// this.$message({
						//   type: 'info',
						//   message: this.$t('i18nn_2e58cb9b52e2a214')
						// });
					});
			},

			//更多
			handleMoreAction(command) {
				// event.stopPropagation();
				// this.FilePdfRotateOpenTime = new Date().getTime();
				if ('batchDownloadAction_1' == command) {
					this.batchDownloadAction('1');
				} else if ('batchDownloadAction_2' == command) {
					this.batchDownloadAction('2');
				} else if ('batchDownloadAction_3' == command) {
					this.batchDownloadAction('3');
				}
			},

			//批量下载附件
			batchDownloadAction(type) {
				// event.stopPropagation();
				let dataList = this.multipleSelection;
				// let dataList = this.tableData;
				if (dataList.length < 1) {
					this.$message.warning(this.$t('tips.Please_Sel'));
					return;
				}
				if (!!window.ActiveXObject || 'ActiveXObject' in window) {
					this.$message.warning(this.$t('i18nn_2de6c4eb2e40cf1f'));
				}
				let files = [];
				dataList.forEach((item, index) => {
					if (item.labelUrl) {
						let file_name_arr_pre = item.labelUrl.split('?')[0];
						let file_name_arr = file_name_arr_pre.split('/');
						let old_file_name = file_name_arr[file_name_arr.length - 1];
						// let old_file_name_suffix = old_file_name.split('.')[1];
						let url = item.labelUrl;
						let fileName = item.tranckingNo;
						if ('1' == type) {
							fileName = old_file_name;
						} else if ('2' == type) {
							fileName = item.tranckingNo ? item.tranckingNo : 'un_' + index;;
						} else if ('3' == type) {
							fileName = item.orderNumber ? item.orderNumber : 'un_' + index;
						}
						files.push({
							url: url,
							fileName: fileName,
						});
					}
				});
				if (files.length < 1) {
					this.$message.warning(this.$t('i18nn_c26d5c523b0b69d3'));
					return;
				}
				this.$message.success('当前页，共 ' + files.length + ' 个面单，正在压缩');
				fileZipAndDownloadReName(files, 'ExpressLabels');
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event,row) {
			//   event.stopPropagation();
			//   this.$emit('selectRow',row)
			// },
			//提交数据
			postData(url, formData, callback, type) {
				// let _this = this;
				this.loading_load = true;
				let http = {};
				if ('delete' == type) {
					http = this.$http.delete(url, formData);
				} else {
					http = this.$http.put(url, formData);
				}
				http
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading_load = false;
						if (200 == data.code) {
							callback(data);
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$alert(data.msg, this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading_load = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				// this.selectOption.wh_size_unit = data.data['wh_size_unit'];
			// 				// this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
	}
</style>